<template lang="pug">
    a.card-price(:href="listing.permalink" target="blank" rel="noopener").d-flex.align-center.justify-center
        template(v-if="listing.newPrice")
            v-row.prices(align="center", justify="center" dense)
                v-col.old-price.d-flex.mr-2.justify-center(cols="12" md="auto" v-if="listing.oldPrice && listing.oldPrice.price")
                    .currency.mr-2 {{listing.oldPrice.currencySymbol}}
                    .amount {{listing.oldPrice.price | asCurrency}}
                v-col.new-price.d-flex.justify-center(cols="12" md="auto", v-if="listing.newPrice && listing.newPrice.price")
                    .currency.mr-2 {{listing.newPrice.currencySymbol}}
                    .amount {{listing.newPrice.price | asCurrency}}

        template(v-else)
            .regular-price.d-flex.align-center.justify-center(v-if="!listing.newPrice")
                .currency.mr-2 {{listing.regularPrice.currencySymbol}}
                .amount {{listing.regularPrice.price | asCurrency}}
        //- pre {{listing.regularPrice}}

</template>

<script>
export default {
  name: "CardPrice",
  props: { listing: { type: Object, required: true } }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";

.card-price {
  transition: 0.2s all ease-in;
  display: inline-block;
  width: 100%;
  background: $accent;
  // position: absolute;
  // right: -10px;
  // bottom: 10%;
  // z-index: 1;
  color: black;
  text-decoration: none;
  font-weight: bold;
  padding: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 1.5rem;
  text-align: center;
  text-shadow: 2px 2px rgba(255, 255, 255, 0.5);
  min-height: 3rem;
}

.card-price:hover {
  cursor: pointer;
  transition: 0.2s all ease-in;
  color: $secondary;
}
.old-price {
  color: maroon;
  font-weight: lighter;
  text-decoration: line-through;
  font-size: 1.25rem;
}
.regular-price {
  height: 100%;
}
</style>
